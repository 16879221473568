<script>
export default {
  props: {
    affiliate: {
      type: Object,
      required: true,
    },
    bundle: {
      type: Object,
      required: true,
    },
  },
  computed: {
    url() {
      const { location } = window;
      const { code } = this.affiliate;
      const url = `${location.protocol}//${location.hostname}`;
      const prefix = this.$constants.AFFILIATE_PREFIX;
      const { href } = this.$router.resolve({
        name: 'bundle',
        params: { slug: this.bundle.slug },
      });

      return `${url}${href}?${prefix}${code}`;
    },
  },
};
</script>

<template>
  <article class="box">
    <div class="columns is-vcentered">
      <div class="column">
        <h2 class="title is-5 mb-3">
          {{ bundle.name }}
        </h2>
        <p class="mb-2">
          <b-icon icon="coins" />
          Commission pour ce pack : {{ bundle.commissionRate }}
        </p>
        <a
          class="color-inherit break-words"
          :href="url"
          v-clipboard:copy="url"
          v-clipboard:success="() => $copySuccess()"
          v-clipboard:error="() => $copyError()"
          @click.prevent
        >
          <b-tooltip label="cliquez pour copier">
            <b-icon icon="clipboard" />
          </b-tooltip>
          {{ url }}
        </a>
      </div>
      <div class="column is-narrow">
        <b-button
          type="is-primary is-custom"
          v-clipboard:copy="url"
          v-clipboard:success="() => $copySuccess()"
          v-clipboard:error="() => $copyError()"
        >
          Copier le lien
        </b-button>
      </div>
    </div>
  </article>
</template>
